import React from 'react';
import API from "../utils/API";
import { connect } from 'react-redux';
import {getUser} from "../utils/Auth";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    button: {
        fontSize: 0.76 + 'rem',
        width: "100%",
        padding: "6px"
    },
    spanButton: {
        position: "absolute",
        width: "100%"
    },
    small: {
        fontSize: '0.65vw',
        lineHeight: 1,
    },
    highlight: {
        color: "#3f51b5"
    }
});

/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.getWeekNumber = function(){
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
  };

function mapStateToProps(state) {
    return {
      loggedIn: state.loggedIn,
      selUser: state.selUser
    };
  }

class CalendarSlot extends React.Component  {
    constructor(props) {
        super(props);

        let role = getUser().role;

        // se resta 7 porque empezamos el calendario a las 7am y se divide por 15 porque son las horas del calendario (22 - 7)
        let s = ((parseFloat(props.slot.hora_inicio.split(":")[0]) + parseFloat(props.slot.hora_inicio.split(":")[1]/60) - 7)); // Start slot
        let e = ((parseFloat(props.slot.hora_fin.split(":")[0]) + parseFloat(props.slot.hora_fin.split(":")[1]/60) - 7)); // end slot
        let pos =21 +  42* s;
        let height = 42*(e-s) -4;
        let ahora = new Date();
        let slot_fecha = new Date(props.dia);
        slot_fecha.setHours(parseInt(props.slot.hora_inicio.split(":")[0]), parseInt(props.slot.hora_inicio.split(":")[1]),0,0);
        let bloqueado = props.slot.cierre;
        let motivo = props.slot.motivo;
        let color = "primary";
        if(bloqueado){
            color= "default";
        }
        if (props.slot.ocupado >= props.slot.aforo){
            bloqueado = true;
            motivo = "Aforo alcanzado";
            color = "secondary"
        }

        if (slot_fecha <= ahora){
            bloqueado= true;
            motivo = "Hora y día anterior";
            color = "secondary"
        }

        let max = Math.min((props.slot.aforo-props.slot.ocupado-1),props.actividad.max_acomp);
        let mr = [0];
        for(let i=1; i<=max; i++){
            mr.push(i);
        }

        // se deshabilita un slot si la fecha es anterior
        let dis = (slot_fecha <= ahora);
        // si el rol es admin o oper no se deshabilita
        if(role===1 || role ===2) // Operador (2) o admin (1)
            dis =  false; 

        let l =0;
        let w =100;
        let ts = 0.76;
        if(this.props.slot.sharedslot >0)
        {
            ts = 0.6
            w = 50;
            if(this.props.slot.sharedslot === 2)
                l = 50;
        }

        this.state = {libres: this.props.slot.aforo-this.props.slot.ocupado, openBloqueado: false, openReserva: false, openError: false, bloqueado: bloqueado, motivo: motivo, pos:pos, height: height, color: color, disabled: dis, slot_fecha: slot_fecha, mensaje_error:'', num_reservas:1, num_acomp:0, max_reservas: mr, left: l, width: w, textsize: ts};

        this.handleClose= this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleConfirmar = this.handleConfirmar.bind(this);
        this.handleClickOpenError = this.handleClickOpenError.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.confirmarReserva = this.confirmarReserva.bind(this);
        this.handleConfirmarContinuar = this.handleConfirmarContinuar.bind(this);

    }
  
    handleClose() {
        this.setState({openBloqueado: false, openReserva: false, openError: false});
    }

    handleClickOpen() {
        let role = getUser().role;
        if(this.state.bloqueado && role===0){
            this.setState({openBloqueado: true});
        }else{
            this.setState({openReserva: true});
        }
    };

    handleClickOpenError() {
        this.setState({openError: true});
    };

    async confirmarReserva(irMisReservas) {
        let token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data= {
            fecha:this.state.slot_fecha.getTime(),
            ActividadeId: this.props.slot.ActividadeId,
            UserName: (this.props.selUser!==null)?this.props.selUser: getUser().username,
            num_reservas: this.state.num_reservas+parseInt(this.state.num_acomp),
            HorarioId: this.props.slot.id
        }
        console.log(data);
        let reserva = await API.post('reservas/', data, config);
        console.log(reserva.data);
        let role = getUser().role;
        if(reserva.data.status==="ok"){
            if(this.props.selUser!==null || role===3){
                if(irMisReservas){
                    if(role===1){
                        this.props.dispatch({ type: "SELUSER", user: null })
                        this.props.history.push("/adm/reservas");
                    }else{
                        this.props.history.push("/misreservas");
                    }
                }else{
                    this.handleClose()
                    if(this.state.libres-1===0){
                        this.setState({
                            libres: this.state.libres-1, 
                            bloqueado: true,
                            motivo: "Aforo alcanzado",
                            color: "secondary"
                        });
                    }else{
                        this.setState({libres: this.state.libres-1});
                    }
                }
                
            }else{
                this.props.history.push("/misreservas");
            }
        }else{
            this.setState({mensaje_error: reserva.data.message});
            this.handleClickOpenError();
        }
    }

    async handleConfirmar() {
        this.confirmarReserva(true);
    }

    async handleConfirmarContinuar(){
        this.confirmarReserva(false);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        let role = getUser().role; // Role del que esta realizando la accion
        let descripcion="";
        if(this.props.slot.descripcion){
            let d= this.props.slot.descripcion.split("/");
            if(d.length>1){
                let w= this.state.slot_fecha.getWeekNumber();
                descripcion=d[w%d.length];
            }else{
                descripcion=d[0];
            }
        }
        return (
            <div>
            <Tooltip title={this.state.bloqueado?t(this.state.motivo):t("Aforo disponible")+": "+this.state.libres.toString()} >
                <span className={classes.spanButton} style={{top: this.state.pos+"px", height: this.state.height+"px", width: this.state.width+"%", left: this.state.left+"%"}}>
                <Button onClick={this.handleClickOpen} color={this.state.color} disabled={this.state.disabled} className={classes.button}  style={{height: this.state.height+"px"}} variant="contained">
                    {(this.props.slot.descripcion)?(
                        <small className={classes.small} style={{fontSize: this.state.textsize+"rem"}}>{descripcion}<br/>{this.props.slot.hora_inicio.slice(0,-3)}-{this.props.slot.hora_fin.slice(0,-3)}</small>
                    ):(
                        <>{this.props.slot.hora_inicio.slice(0,-3)}-{this.props.slot.hora_fin.slice(0,-3)}</>
                    )}
                </Button>
                </span>
            </Tooltip>

            <Dialog open={this.state.openBloqueado} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"Hora no disponible"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    El día seleccionado no está disponible por {this.state.motivo}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                    {t('Ok')}
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={this.state.openReserva} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{t("Confirmar reserva")}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t("Desea confirmar la reserva para el día ")}<b className={classes.highlight}>{this.state.slot_fecha.getDate()}/{this.state.slot_fecha.getMonth()+1}/{this.state.slot_fecha.getFullYear()} de {this.props.slot.hora_inicio.slice(0,-3)} a {this.props.slot.hora_fin.slice(0, -3)}</b> {t("en la actividad ")} <b className={classes.highlight}>{t(this.props.actividad.nombre)} {t(this.props.slot.descripcion)}</b>{t(" de la instalación ")}<b className={classes.highlight}>{t(this.props.instalacion.nombre)}</b>?
                    
                    { (this.props.actividad.max_acomp && (role===0 || role===2))>0?(
                        <>
                        <br/>
                        {t("Número de plazas de acompañantes")}:
                        <span style={{color:'#aa0000', paddingRight: "5px"}}>
                            {"Cumplimentar sólo cuando hayan acompañantes menores de 18 años y dependientes"}
                        </span>
                        <Select
                        id="reservas"
                        value={this.state.num_acomp}
                        onChange={(event)=>this.setState({num_acomp: event.target.value })}
                        >
                            {this.state.max_reservas.map(r=>
                                <MenuItem value={r}>{r}</MenuItem>
                            )}
                        </Select>

                        </>
                    ):(<></>)}

                    { (role===3 || role===1)?( // administrador o usuario sin restricciones
                        <>
                        <br/>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type="number"
                            id="num_acomp"
                            label={t('Número de acompañantes')}
                            name="num_acomp"
                            value={this.state.num_acomp}
                            autoComplete={t('Número de acompañantes')}
                            onChange={this.handleInputChange}
                        />
                        </>
                    ):(<></>)}
                    


                    <br/>
                    <b className={classes.highlight}>{t("Para acceder a la instalación se deberá estar al corriente del pago de la Tasa.")}</b>
                    <br />
                    <br />
                    {this.props.actividad.notas_reserva!=="" && 
                        <small>*Notas: {this.props.actividad.notas_reserva}</small>
                    }
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} color="secondary" autoFocus>
                    {t("Cancelar")}
                </Button>
                <Button onClick={this.handleConfirmar} color="primary" autoFocus>
                    {t("Confirmar")}
                </Button>
                { (role===3 || role===1 || role ===2)?( // administrador, operador o usuario sin restricciones
                        <Button onClick={this.handleConfirmarContinuar} color="primary" autoFocus>
                            {t("Confirmar y continuar en esta pantalla")}
                        </Button>
                    ):(<></>)}
                </DialogActions>
            </Dialog>

            <Dialog open={this.state.openError} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{"Error al guardar su reserva"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.mensaje_error}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                    Ok
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    };
};

export default withTranslation()(withRouter(withStyles(useStyles)(connect(mapStateToProps)(CalendarSlot))));
