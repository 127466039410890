import React from 'react';
import API from "../utils/API";
import {getUser} from "../utils/Auth";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { getHeaderAuth } from '../utils/Auth';
import { withTranslation } from 'react-i18next';
import { checkDNI } from '../utils/DNI';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: "blur(10px)",
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: "4px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
      width:100+"vw",
      height:100+"vh",
      background: "url('/images/backgrounds/1.jpeg') no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "auto",
      padding: "40px 0"
  }
});

class Perfil extends React.Component{

  constructor(props) {

      super(props);
      this.state = {id:0, user: '', pass: '', nombre:'', apellidos:'', email:'', genero: 0, telefono:'', dni:'', passwordRepeat:'', id_image:(Math.floor(Math.random() * 6) + 1)};
      this.handleInputChange = this.handleInputChange.bind(this);
      this.registrarNuevoUsuario = this.registrarNuevoUsuario.bind(this);
      this.changeGenero = this.changeGenero.bind(this);
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  changeGenero(event){
    this.setState({genero:event.target.value});
  }

  async registrarNuevoUsuario(event) {
    event.preventDefault();
    if(!checkDNI(this.state.dni)){
      this.setState({"status":"ko"});
      this.setState({"mensaje_error":"El dni introducido es inválido."});
      return;
    }
    try{
      let result = await API.put('/user', this.state, getHeaderAuth());
      this.setState({"status":result.data.status});
      if(this.state.status==="ok"){
        this.setState({"mensaje_error":""});
        this.setState({"mensaje":"Cambios correctamente guardados"});
      }else{
        this.setState({"mensaje_error":result.data.message});
      }
    }catch(e)
    {
      this.setState({"status":"ko"});
      this.setState({"mensaje_error":"Error de conexión"});
    }
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
    let userdata = getUser(token);
    
    let perfil = await API.get('/user/profile/'+userdata.username, config);
    this.setState({genero: perfil.data.genero});
    this.setState({nombre: perfil.data.nombre});
    this.setState({user: perfil.data.user});
    this.setState({id: perfil.data.id});
    this.setState({apellidos: perfil.data.apellidos});
    this.setState({email: perfil.data.email});
    this.setState({telefono: perfil.data.telefono});
    this.setState({dni: perfil.data.dni});
  }

  render() {
  
    const  classes  = this.props.classes;
    const { t } = this.props;
    return (

      <div className={classes.background} style={{backgroundImage: "url('/images/backgrounds/"+this.state.id_image+".jpeg')"}}>
              <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    {t("Mi Perfil")}
                    </Typography>
                    <form className={classes.form} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="nombre"
                              label={t("Nombre")}
                              name="nombre"
                              value = {this.state.nombre}
                              autoComplete="Nombre"
                              autoFocus
                              onChange={this.handleInputChange}
                          />
                              <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="apellidos"
                              label={t("Apellidos")}
                              name="apellidos"
                              value = {this.state.apellidos}
                              autoComplete="Apellido"
                              autoFocus
                              onChange={this.handleInputChange}
                          />
                          <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="dni"
                              label={t("DNI")}
                              name="dni"
                              value = {this.state.dni}
                              autoComplete="DNI"
                              autoFocus
                              onChange={this.handleInputChange}
                          />
                          <FormControl variant="outlined" fullWidth style={{marginTop:"16px"}}>
                            <InputLabel id="genero-label">{t("Género")}</InputLabel>
                            <Select
                              labelId="genero-label"
                              id="genero"
                              value={this.state.genero}
                              onChange={this.changeGenero}
                              label={t("Género")}
                            >
                              <MenuItem value={1}>Hombre</MenuItem>
                              <MenuItem value={2}>Mujer</MenuItem>
                              <MenuItem value={3}>Persona no binaria</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label={t("Correo electrónico")}
                              name="email"
                              value = {this.state.email}
                              autoComplete="Correo electrónico"
                              autoFocus
                              onChange={this.handleInputChange}
                          />
                          <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="telefono"
                              label={t("Teléfono")}
                              name="telefono"
                              value = {this.state.telefono}
                              autoComplete="Teléfono"
                              autoFocus
                              onChange={this.handleInputChange}
                          />
                        </Grid>
                        
                      </Grid>
                        
                      <p style={{color:"#f00"}}>{this.state.mensaje_error}</p>
                      <p style={{color:"#0f0"}}>{this.state.mensaje}</p>

                        <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={this.registrarNuevoUsuario}
                        >
                            {t("Guardar cambios")} 
                        </Button>


                        <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        href="/pass"
                        >
                            {t("Cambiar contraseña")} 
                        </Button>
                    </form>
                </div>
            </Container>

        </div>
    );
  };
  
}

export default withTranslation()(withRouter(withStyles(styles)(Perfil)))