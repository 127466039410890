
export const letraDni = (dni) => { 
    return "TRWAGMYFPDXBNJZSQVHLCKE".charAt(dni % 23); 
}

export const convertNIE = (dni) => {
    let start_nie = ["X", "Y", "Z"];
    let start = dni.slice(0,1);
    let index = start_nie.indexOf(start);
    if (index!==-1){
        return String(index)+dni.slice(1);
    }else{
        return dni;
    }
}

export const cleanDNI = (dni)=> {
    // procesamos el numero para estandarizarlo
    dni = dni.toUpperCase();
    dni = dni.split(".").join("");
    dni = dni.split("-").join("");
    dni = dni.split(" ").join("");
    return dni;
}

export const checkDNI = (dni) => {
    // procesamos el numero para estandarizarlo
    dni = cleanDNI(dni);
    // cogemos el digito de control (ultimo caracter)
    let digito = dni.slice(-1);
    // cogemos la parte numerica
    let numero = dni.slice(0, -1);
    // comprobamos si la primera letra es X Y o Z (NIE)
    numero = convertNIE(numero);
    // comprobamos la letra a partir del numero
    let letra = letraDni(parseInt(numero));

    return digito === letra;

}