import React from 'react';
import API from "../utils/API";
import Grid from '@material-ui/core/Grid';
import {NavLink} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

const useStyles = theme => ({
    root: {
      position: "relative"
    },
    deporte: {
        height: 100+"vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        color: "#ffffff",
        fontSize: 40+"px",
        textShadow: "2px 2px 4px #000000",
        paddingTop: 50+"vh",
        textAlign: "center",
        tarnsform: "scale(1)",
        transition: "all .25s",
        display: "block",
        textDecoration: "none",
        "&:hover": {
            transform: "scale(1.1)",
            transition: "all .25s"
        },
    },
});

function mapStateToProps(state) {
    return {
        leido: state.leido
    };
  }

class Deportes extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {deportes: []};
        this.handleClose = this.handleClose.bind(this);
    }

    async componentDidMount() {
        let token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let deportes = await API.get('/deportes', config);
        this.setState({deportes: deportes.data});
        console.log(this.state);
      }

    handleClose() {
        this.props.dispatch({type: "LEIDO"});
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        let n_dep = 12/this.state.deportes.length;
        return (
        <div className={classes.root}>
            <Grid container spacing={0} style={{height: "100vh",overflow:"hidden"}}>

                { this.state.deportes.map(deporte => 
                <Grid item key={deporte.id} xs={12} sm={n_dep} style={{height: "100vh",overflow:"hidden"}}>
                    <NavLink 
                        to={`/deporte/${deporte.id}`} 
                        className={classes.deporte} 
                        style={{backgroundImage:"url(/images/"+deporte.imagen+")"}}>
                            {t(deporte.nombre)}
                    </NavLink>
                    
                </Grid>)}
                
            </Grid>

            <Dialog open={!this.props.leido} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">{t("Información importante")}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Si no va a utilizar su reserva, recuerde borrarla antes del día y hora reservada. 3 reservas no utilizadas en un mes impiden, durante una semana, poder volver a reservar en esa instalación. Cuando acuda a las instalaciones deportivas a usar su reserva, no olvide que el personal de la Concejalía de Deportes se la marque como utilizada.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                    {t('Ok')}
                </Button>
                </DialogActions>
            </Dialog>

        </div>
        );
    }
};

export default withTranslation()(withStyles(useStyles)(connect(mapStateToProps)(Deportes)));