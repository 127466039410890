import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
    // console.log("==============");
    // console.log(rest)
    // console.log("==============");
    return(
    <Route {...rest} render={(props) => (rest.loggedIn === true
        ? <Component {...props} />
        : <Redirect to='/login' />)
    } />
    );
}

function mapStateToProps(state) {
    return {
      loggedIn: state.loggedIn
    };
}

  
export default (connect(mapStateToProps)(PrivateRoute))