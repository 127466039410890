import { forwardRef } from 'react';
import React from 'react';
import API from "../../utils/API";
import {getHeaderAuth, getUser} from "../../utils/Auth";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CalendarToday from '@material-ui/icons/CalendarToday';
import EventBusy from '@material-ui/icons/EventBusy';
import { withTranslation } from 'react-i18next';
//import { checkDNI } from '../../utils/DNI';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import exportFromJSON from 'export-from-json';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: "blur(10px)",
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: "4px"
  },
  root:{
    padding: "150px 0 45px 0"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
      width:100+"vw",
      height:100+"vh",
      background: "url('/images/backgrounds/1.jpeg') no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "auto",
      padding: "40px 0"
  },
  cardsContainer: {
    margin: "auto",
    display: "flex"
  },
  
});

function mapStateToProps(state) {
  return {
    loggedIn: state.loggedIn,
    selUser: state.selUser
  };
}


class Colegios extends React.Component{

  constructor(props) {

      super(props);
      this.state = {users:[]};
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onDelete = this.onDelete.bind(this);
      this.newUser = this.newUser.bind(this);
      this.selectUser = this.selectUser.bind(this);
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
      
    let users = await API.get('/user/colegios', config);
    this.setState({users: users.data});
    
  }

  async onDelete(id){
    console.log("SE BORRA ELEMENTO CON ID: "+id);
    let result = await API.delete('/user/'+id, getHeaderAuth());
    if(result.data.status==="OK"){
      this.setState({users: this.state.users.filter(function(user) { 
        return user.id !== id 
      })});
    }else{
      alert(result.data.message);
    }
  }

  async newUser(newData){
    newData["pass"]=Math.random().toString(36).substring(2,10);
    newData["rol"] = 5; // Forzamos el rol del usuario a 5 (Colegio)
    //console.log(newData);
    // En el caso de los colegios no vamos a comprobar el CIF
    // if(!checkDNI(newData.dni)){
    //   throw new Error("El dni introducido es inválido.");
    // }
    let result = await API.post('/user/', newData);
    if(result.data.status==="ok"){
      newData["id"]= result.data.id;
      console.log(newData);
      this.setState({users: [...this.state.users, newData]});
      return;
    }else{
      throw new Error(result.data.message);
    }
    
  }

  async updateUser(newData, oldData){
    // if(!checkDNI(newData.dni)){
    //   throw new Error("El dni introducido es inválido.");
    // }
    console.log(newData);
    let result = await API.put('/user', newData, getHeaderAuth());
    if(result.data.status==="ok"){
      // newData["id"]= result.data.id;
      // console.log(newData);
      // this.setState({users: [...this.state.users, newData]});
      const dataUpdate = [...this.state.users];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      this.setState({users:[...dataUpdate]});
      return;
    }else{
      throw new Error(result.data.message);
    }
  }

  selectUser(user){
    this.props.dispatch({ type: "SELUSER", user: user });
    this.props.history.push("/deportes");
  }

  verSanciones(user_id){
    this.props.history.push("/adm/sanciones/"+user_id);
  }

  render() {
    let user = getUser();
    // Por defecto administrador
    // eslint-disable-next-line
    let user_roles= {3:"Usuario sin limites", 0:"Usuario", 1:"Administrador", 2:"Operario", 4:"Monitor"};
    let allow_edit = {
      onRowAdd: newData =>
      new Promise((resolve, reject) => {
        this.newUser(newData).then(()=>{
          resolve()
        }).catch((e)=>{
          alert(e);
          reject()
        })
      }),
      onRowDelete: oldData =>
      new Promise((resolve, reject) => {
          //const dataDelete = [...this.state.users];
          //const index = oldData.tableData.id;
          this.onDelete(oldData.id).then(()=>{
            //dataDelete.splice(index, 1);
            //this.setState({reservas:dataDelete});
            resolve();                  
          })
      }),
      onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            this.updateUser(newData, oldData).then(()=>{
              resolve();
            }).catch((e)=>{
              alert(e);
              reject();
            })
          }),
    };
    // Para operarios
    if(user.role===2){
      user_roles= {0:"Usuario"};
      allow_edit= {
        isEditable: rowData => rowData.rol === 0,
        onRowAdd: newData =>
        new Promise((resolve, reject) => {
          this.newUser(newData).then(()=>{
            resolve()
          }).catch((e)=>{
            alert(e);
            reject()
          })
        }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            this.updateUser(newData, oldData).then(()=>{
              resolve();
            }).catch((e)=>{
              alert(e);
              reject();
            })
          }),
      };
    }
    const  classes  = this.props.classes;
    //const { t } = this.props;
    return (

      
        <div className={classes.root}>

          <Container  maxWidth={false}>
            <MaterialTable
            icons={tableIcons}
            columns={[
              { title: "Colegio", field: "colegio", customFilterAndSearch: (term, rowData) => rowData.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase().includes(term.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) },
              { title: "Usuario", field: "user", customFilterAndSearch: (term, rowData) => rowData.user.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase().includes(term.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) },
              { title: "CIF colegio", field: "dni"},
              { title: "Correo electrónico contacto", field: "email"},
              { title: "Teléfono contacto", field: "telefono"},
              { title: "Nombre responsable", field: "nombre", customFilterAndSearch: (term, rowData) => rowData.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase().includes(term.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) },
              { title: "Apellidos responsable", field: "apellidos", customFilterAndSearch: (term, rowData) => rowData.apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase().includes(term.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) },
              { title: "Fecha de Nacimiento", 
                field: "fecha_nacimiento", 
                render: rowData => { let d = new Date(rowData.fecha_nacimiento); return(<p>{d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()}</p>) },
                editComponent: props => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      placeholder="Fecha de nacimiento"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      value={props.value}
                      onChange={(e)=>{props.onChange(e)}}
                    />
                  </MuiPickersUtilsProvider>
                )
              },
              
              { title: "Género", field: "genero", lookup: {1:"Hombre",2:"Mujer",3:"Persona no binaria"}},
              { title: "Bloqueado", field: "blocked",
                render : rowData => {
                  if(rowData.blocked){
                    return(<Chip variant="outlined" size="small" label="bloqueado" color="secondary" />);
                  }else{
                    return(<Chip variant="outlined" size="small" label="autorizado" color="primary" />);
                  }
                },
                editComponent: props => (
                  <Switch
                    checked={props.value}
                    onChange={(e)=>{props.onChange(e.target.checked)}}
                    name="Bloqueado"
                  />
                )
              },
            ]}
            data={this.state.users}
            //onRowClick={((evt, selectedRow) => console.log(new Date(selectedRow.fecha+" "+selectedRow.Horario.hora_inicio)))}
            onChangeRowsPerPage={()=>{
              //document.body.scrollTop = 0; // For Safari
              //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
              setTimeout(()=>{window.scrollTo(0,0)},1000);
              console.log("Go to top");
            }}
            onChangePage={()=>{
              window.scrollTo(0,0);
              console.log("Go to top");
            }}
            actions={[
                {
                    icon: CalendarToday,
                    tooltip: 'Crear reserva',
                    onClick: (event, rowData) => this.selectUser(rowData.user)
                  },
                  {
                    icon: EventBusy,
                    tooltip: 'Sanciones',
                    onClick: (event, rowData) => this.verSanciones(rowData.id)
                  }
              ]}
            editable= {allow_edit}
            localization={
              { 
                body: { 
                  addTooltip: "Añadir",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  editRow: { 
                    deleteText: '¿Deseas borrar el usuario?' ,
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Guardar"
                  } 
                }, 
                header:{
                  actions: "Acciones"
                },
                toolbar: {
                  searchTooltip: "Buscar", 
                  searchPlaceholder:"Buscar",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  exportName: "Exportar como"
                }, 
                pagination: {labelRowsSelect: "filas"} }}
            options={{
              exportButton: true,
              exportCsv: (columns, data) => {
                let export_data=[];
                for(let d  of data){
                  let r={};
                  for(let c of columns){
                    let val = c.field.split('.').reduce(function(m, n){ return m[n] }, d);
                    if(c.lookup === undefined)
                      r[c.title]=val;
                    else
                      r[c.title]=c.lookup[val];
                  }
                  export_data.push(r);
                }
                //console.log(export_data);
                //alert('You should develop a code to export ' + data.length + ' rows');
                exportFromJSON({ data:export_data, filename:"export", exportType:"xls", withBOM:true });
              },
              emptyRowsWhenPaging: false,
              paging: true,
              pageSizeOptions: [5,10,20,50,100,500, { value: -1, label: "All" }],
              filtering: true,
              headerStyle: {
                  padding: "12px",
                    fontSize: "0.8em",
                },
              cellStyle: {
                padding: "12px",
                fontSize: "0.8em",
              }
            }}
            title="Usuarios"
          />
          </Container>
        </div>
  
    );
  };
  
}

export default withTranslation()(withRouter(withStyles(styles)(connect(mapStateToProps)(Colegios))))