import React from 'react';
import API from "../utils/API";
import { cleanAuth } from "../utils/Auth";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withTranslation } from 'react-i18next';


const styles = theme => ({
    paper: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backdropFilter: "blur(10px)",
      backgroundColor: 'rgba(255,255,255,0.3)',
      borderRadius: "4px"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    background: {
        width:100+"vw",
        height:100+"vh",
        background: "url('/images/backgrounds/1.jpeg') no-repeat",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "auto",
        padding: "40px 0"
    }
});

function mapStateToProps(state) {
  return {
    loggedIn: state.loggedIn
  };
}

class Remember extends React.Component{
    
  constructor(props) {
      super(props);
      this.state = {user: '', error:'ok', mensaje_error: '',id_image:(Math.floor(Math.random() * 6) + 1)};
      
      this.handleInputChange = this.handleInputChange.bind(this);
      this.remember = this.remember.bind(this);
      this.keyPress = this.keyPress.bind(this);
      cleanAuth();
      this.props.dispatch({ type: "LOGOUT" });
  }
  
  async remember(e)
  {
    const { t } = this.props;
    e.preventDefault();
    const user = {
      username: this.state.user
    };
    try{
      let rep = await API.post('/user/remember', user);
      this.setState({"error":rep.data.status});
      if (rep.data.status==="ok"){
        this.setState({"mensaje_error":t("Se ha enviado un correo electronico asociado a tu usuario con tu nueva contraseña.")});
      }else{
        //Show error 
        this.setState({"mensaje_error":rep.data.message});
      }
    }catch(e){
      this.setState({"error":"ko"});
      this.setState({"mensaje_error":"Error de conexión"});
      console.log(e.message);
    }
  }; 

  keyPress(e){
      if(e.keyCode === 13){
        this.checkLogin(e);
      }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  render() {
    const  classes  = this.props.classes;
    const { t } = this.props;
    return (
        <div className={classes.background} style={{backgroundImage: "url('/images/backgrounds/"+this.state.id_image+".jpeg')"}}>
    <Container component="main" maxWidth="xs">

      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Recordar contraseña')}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={this.state.error === "ko"}
            fullWidth
            id="user"
            label={t("Nombre de usuario")}
            name="user"
            autoComplete="Nombre de usuario"
            autoFocus
            onChange={this.handleInputChange}
            onKeyDown={this.keyPress}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.remember}
          >
            {t("Recordar")}
          </Button>
          <p style={{color:"#f00"}}>{this.state.mensaje_error}</p>
        </form>
      </div>
    </Container>
    </div>
        );
  };

}

export default withTranslation()(withRouter(withStyles(styles)(connect(mapStateToProps)(Remember))))
