import React from 'react';
import API from "../utils/API";
import Button from '@material-ui/core/Button';
import CalendarSlot from './calendarSlot';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import {getUser} from "../utils/Auth";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

const useStyles = theme => ({
    container: {
        height: '91vh',
        display: 'block',
        width: '90%',
        overflow: "auto"
    },
    calendarHeader: {
        display: "flex",
        position: "fixed",
        width: "75%",//59.999999999%//"calc(66.66667% - 71px)",
        background: "#ffffff",
        zIndex: 2
    },
    calendarContent:{
        display: "flex",
        overflow: "auto",
        marginTop: "73px",
        //marginBottom: "50px"
    },
    horas: {
        display: 'block',
        width: '25%',
        borderRight: '1px solid #dadada',
    },
    hora: {
        position: "relative",
        height: "42px",
    },
    line: {
        position: "absolute",
        top: "50%",
        width: "100%",
        borderBottom: "1px solid #dadada"
    },
    text: {
        position: "absolute",
        top: "50%",
        background: "#ffffff",
        fontWeight: 200,
        fontSize: "24px",
        marginTop: "-12px"
    },
    dia: {
        display: 'block',
        width: '12.5%',
        borderRight: '1px solid #dadada',
        position: "relative"
    },
    slots: {
        position: "relative",
        height: "calc(90vh - 72px)"
    },
    active: {
        color: "#3f51b5",
        "& h6": {
            background: "#3f51b5",
            color: "#fff",
            borderRadius: "50%"
        }
    },
    header: {
        borderBottom: "1px solid #333333",
        height: "72px",
        width: "100%",
        textAlign: "center",
        '& h4': {
            fontSize: "1.2em",
            fontWeight: 400,
            margin: 0,
            paddingTop: 0.6+'em'
        },
        '& h5': {
            fontSize: "1.2em",
            fontWeight: 200,
            margin: 0,
            paddingTop: 0.6+'em',
            //marginLeft: 0.2+'em'
        },
        '& h6': {
            fontSize: "1.5em",
            fontWeight: 600,
            margin: "0 auto",
            width: "34px",
            height: "34px",
            lineHeight: "34px",
            textAlign: "center"
        }
    }
});

class CalendarBooking extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {slots:[], dias:[], hoy: new Date(), semana: 0};
        this.days = ["DOM","LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.handleNextWeek = this.handleNextWeek.bind(this);
        this.updateWeek = this.updateWeek.bind(this);
        this.handlePreviousWeek = this.handlePreviousWeek.bind(this);
    }
  
    async updateWeek(day) {
        let token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
                
        let slots = await API.get('/horarios/w/'+this.props.actividadId+"/"+day.getTime(), config);
        this.setState({slots: slots.data});

        // Vamos a generar todos los dias de la semana
        var week_day = day.getDay();
        if(week_day===0)
            week_day=7;
        var start_dia = new Date(day.getTime());
        // lunes
        start_dia.setDate(1);
        start_dia.setMonth(day.getMonth());
        start_dia.setDate(day.getDate() - week_day + 1);
        let dias_semana = []
        for(let i =0; i < 7; i++){
            let wd = new Date(start_dia.getTime());
            wd.setDate(1);
            wd.setMonth(start_dia.getMonth());
            wd.setDate(start_dia.getDate()+i);
            dias_semana.push(wd);
        }
        this.setState({dias: dias_semana});
    }

    async componentDidUpdate(prevProps) {
        if(this.props.actividadId !== prevProps.actividadId){
            let hoy = new Date();
            await this.updateWeek(hoy);
        }
        
    }

    async handleNextWeek(){
        let next = new Date();
        next.setDate(this.state.hoy.getDate() + 7);
        await this.updateWeek(next);
        this.setState({semana: 1});
    }

    async handlePreviousWeek(){
        await this.updateWeek(this.state.hoy);
        this.setState({semana: 0});
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        let role = getUser().role;

        let navegacion;
        if(role === 1 || role ===3) // usuario sin limites o admin
        {
            navegacion = <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}><KeyboardDatePicker format="dd/MM/yyyy" id="date-picker-inline" value={this.state.hoy} onChange={(e)=>{ this.setState({hoy:e}); this.updateWeek(e) }} style={{marginTop:"16px"}}/> </MuiPickersUtilsProvider>
        }else{
            if(this.state.semana === 0){
                navegacion = <h4><Button onClick={this.handleNextWeek} color="primary">{t('Próxima semana')}</Button></h4>
            }else{
                navegacion = <h4><Button onClick={this.handlePreviousWeek} color="primary">{t("Semana actual")}</Button></h4>
            }
        }

        return (
            <div className={classes.container}>
                <div className={classes.calendarHeader}>
                    <div className={classes.horas} style={{border:"none"}}>
                        <div className={classes.header}>
                            {navegacion}
                        </div>
                    </div>
                    {this.state.dias.map((value, index) => (
                    <div key={value} className={classes.dia} style={{border:"none"}}>
                        <div className={`${classes.header} ${value.getDate()===this.state.hoy.getDate() ? classes.active : ""}`}>
                            <h5>{t(this.days[value.getDay()])}</h5>
                            <h6>{value.getDate()}</h6>
                        </div>
                    </div>
                    ))}
                </div>
                <div className={classes.calendarContent}>
                    <div className={classes.horas}>
                        {["07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"].map((value) => (
                        <div key={value} className={classes.hora}>
                            <div className={classes.line}></div>
                            <div className={classes.text}>
                                {value}
                            </div>
                        </div>
                        ))}    
                    </div>
                    {this.state.dias.map((value, index) => (
                    <div key={value} className={classes.dia}>
                        <div className={classes.slots}>
                        {this.state.slots[index].map((slot) => (
                            <CalendarSlot slot={slot} dia={value} actividad={this.props.actividad} instalacion={this.props.instalacion}></CalendarSlot>
                        ))}
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            

        );
    };
};

export default withTranslation()(withStyles(useStyles)(CalendarBooking));