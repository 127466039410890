import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Login from './views/login';
import Instalaciones from './views/instalaciones';
import Actividades from './views/actividades';
import Calendar from './views/calendar';
import Deportes from './views/deportes';
import register from './views/register';
import perfil from './views/perfil';
import perfil_pass from './views/perfil_pass';
import PoliticaPrivacidad from './views/politicaPrivacidad';
import AvisoLegal from './views/avisoLegal';
import Cookies from './views/cookies';
import MisReservas from './views/misReservas';
import PiePagina from './views/piePagina';
import Navegacion from './views/navegacion';
import Remember from './views/remember';
import Reservas from './views/adm/reservas';
import Users from "./views/adm/users";
import Sanciones from "./views/adm/sanciones";
import Cierres from "./views/adm/cierres";
import {checkAuth} from './utils/Auth';
import PrivateRoute from "./components/privateRouter";
import { CookieBanner } from '@palmabit/react-cookie-law';
import colegios from './views/adm/colegios';

const initialState = {
  loggedIn: checkAuth(),
  selUser: null,
  leido: false // Leido las instrucciones iniciales
};

function reducer(state = initialState, action) {
  console.log('reducer', state, action);
  switch(action.type) {
    case "LOGIN":
      return Object.assign({}, state, {
        loggedIn: true
      })
    case "LOGOUT":
      return Object.assign({}, state, {
        loggedIn: false, 
        leido: false
      })
    case "SELUSER":
      return Object.assign({}, state, {
        selUser: action.user
      })
    case "LEIDO":
      return Object.assign({}, state, {
        leido: true
      })
    default:
      return state;
  }
}

const store = createStore(reducer);

function App() {
  return (
    <div className="App">
    <Provider store={store}>
      <BrowserRouter>
        <Navegacion/>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={register} />
          <Route path="/remember" component={Remember} />
          <PrivateRoute path="/deportes" component={Deportes} />
          <PrivateRoute path="/perfil" component={perfil} />
          <PrivateRoute path="/pass" component={perfil_pass} />
          <Route path="/politicaPrivacidad" component={PoliticaPrivacidad} />
          <Route path="/avisoLegal" component={AvisoLegal} />
          <Route path="/cookies" component={Cookies} />
          <PrivateRoute path="/misReservas" component={MisReservas} />
          <PrivateRoute path={"/deporte/:did/instalacion/:iid/actividad/:aid"}  component={Calendar} />
          <PrivateRoute path={"/deporte/:did/instalacion/:iid/:pid?"}  component={Actividades} />
          <PrivateRoute path={"/deporte/:id"}  component={Instalaciones} />
          <PrivateRoute path={"/actividades"}  component={Actividades} />
          
          <PrivateRoute path={"/adm/reservas"}  component={Reservas} />
          <PrivateRoute path={"/adm/users"}  component={Users} />
          <PrivateRoute path={"/adm/colegios"}  component={colegios} />
          <PrivateRoute path={"/adm/sanciones/:uid"}  component={Sanciones} />
          <PrivateRoute path={"/adm/cierres"}  component={Cierres} />
          <Route path="/" component={Login} />
        </Switch>
      </BrowserRouter>
      <PiePagina/>
      <CookieBanner
        styles={{
          dialog: { 
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: "100000",
            backgroundColor: "rgb(248, 247, 247)",
            padding: "10px",
           },
           checkbox:{
            position: "absolute",
            top: "1px",
            left: "0px",
            width: "14px",
            height: "14px",
            zIndex: 2,
            cursor: "pointer",
           },
           optionLabel: {
            height: "auto",
            width: "auto",
            minHeight: "14px",
            fontSize: "12pt",
            color: "rgb(18, 18, 18)",
            display: "inline-block",
            padding: "1px 0px 0px 20px",
            position: "relative",
            top: "0px",
            left: "0px",
            zIndex: 1,
            cursor: "default",
            verticalAlign: "top",
           },
           button:{
            display: "inline-block",
            fontWeight: "300",
            fontSize: "12px",
            background: "#5677FC",
            color: "#fff",
            height: "36px",
            textAlign: "center",
            lineHeight: "36px",
            textTransform: "uppercase",
            padding: "0 60px",
            borderRadius: "3px",
            position: "relative",
            top: 0,
            left: 0,
            overflow: "hidden",
            border: "none",
            cursor: "pointer"
           }
        }}
        message="Esta página utiliza cookies propias y de terceros para analizar y personalizar tu navegación. Pulsa “Aceptar” si autorizas todas estas cookies. También puedes configurar o rechazar las cookies."
        wholeDomain={true}
        onAccept = {() => {}}
        onAcceptPreferences = {() => {}}
        onAcceptStatistics = {() => {}}
        showMarketingOption = {false}
        policyLink = "http://localhost:3000/politicaPrivacidad"
        privacyPolicyLinkText= "Política de privacidad"
        necessaryOptionText= "Obligatorias para el funcionamiento"
        preferencesOptionText= "Preferencias de usuario"
        statisticsOptionText= "Estadísticas"
      />
    </Provider>
    </div>
  );
}

export default App;
