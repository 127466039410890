import React from 'react';
import API from "../utils/API";
import {getUser} from "../utils/Auth";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import BookingCard from '../components/bookingCard';
import { getHeaderAuth } from '../utils/Auth';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: "blur(10px)",
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: "4px"
  },
  root:{
    padding: "75px 0 45px 0"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
      width:100+"vw",
      height:100+"vh",
      background: "url('/images/backgrounds/1.jpeg') no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "auto",
      padding: "40px 0"
  },
  cardsContainer: {
    margin: "auto",
    display: "flex"
  }
});

class MisReservas extends React.Component{

  constructor(props) {

      super(props);
      this.state = {reservas:[], reservas_no_conf:[]};
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onDelete = this.onDelete.bind(this);
      this.onDeleteError = this.onDeleteError.bind(this);
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    //let userdata = JSON.parse(base64.decode(token.split(".")[1]));
    let userdata = getUser(token);
      
    let perfil = await API.get('/user/profile/'+userdata.username, config);
    let reservas = await API.get('/reservas/user/'+perfil.data.id, config);
    let reservas_no_conf = await API.get('/reservas/user/noconfpen/'+perfil.data.id, config);
    this.setState({reservas: reservas.data, reservas_no_conf: reservas_no_conf.data});
    console.log(reservas.data);
    
  }

  async onDelete(id){
    console.log("SE BORRA ELEMENTO CON ID: "+id);
    let result = await API.delete('/reservas/'+id, getHeaderAuth());
    if(result.data.status==="OK"){
      this.setState({reservas: this.state.reservas.filter(function(reserva) { 
        return reserva.id !== id 
      })});
    }else{
      alert(result.data.message);
    }
  }

  async onDeleteError(id){
    alert("No se puede borrar esta reserva")
  }

  render() {
  
    const  classes  = this.props.classes;
    //const { t } = this.props;
    return (

      
        <div className={classes.root}>

          <Container  maxWidth="false">
            <h2>Tus próximas reservas</h2>
            {this.state.reservas.length===0 &&
              <p>No tienes reservas actualmente.</p>
            }
              {/* <div className={classes.cardsContainer}> */}
              <Grid container spacing={3}>
                { this.state.reservas.map(reserva => 
                  <Grid key={reserva.id} item xs={12} sm={6} md={4} lg={3}> <BookingCard onDelete={this.onDelete} id={reserva.id} reserva={reserva} ></BookingCard></Grid>
                )}
              </Grid>
              {/* </div> */}
          </Container>
          { this.state.reservas_no_conf.length >0 && 
          <Container maxWidth="false">
            <h2>Reservas no confirmadas en los últimos 30 días</h2>
            <Grid container spacing={3}>
                { this.state.reservas_no_conf.map(reserva => 
                  <Grid key={reserva.id} item xs={12} sm={6} md={4} lg={3}> <BookingCard id={reserva.id} reserva={reserva} ></BookingCard></Grid>
                )}
              </Grid>
          </Container>
          }
        </div>
  
    );
  };
  
}

export default withTranslation()(withRouter(withStyles(styles)(MisReservas)))