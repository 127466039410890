import React from 'react';
import API from "../utils/API";
import Grid from '@material-ui/core/Grid';
import {NavLink} from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
//card

import CalendarBooking from '../components/calendarBooking';

const useStyles = theme => ({
  root: {
    position: "relative",
    height: "100vh",
    overflow: "hidden"
  },
  contenedor: {
    height: 100+"vh",
  },

    cuadro: {
        height: "calc( 100vh + 115px)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "#ffffff",
        fontSize: 26+"px",
        textShadow: "2px 2px 4px #000000",
        textAlign: "center",
        tarnsform: "scale(1)",
        transition: "all .25s",
        textDecoration: "none",
        paddingTop: "calc( 50vh - 115px )"
        /*"&:hover": {
            transform: "scale(1.1)",
            transition: "all .25s"
        },*/
    },

    card: {
      marginTop:"20px",
 
      width: "40vw", 
      height: "200px",
      boxShadow: "0px -2px 10px #eee",
  },

  icon: {
    backgroundSize: "cover",
    borderRadius: "50%",
    width: "150px",
    height: "150px",
    marginLeft: "calc( 50% - 75px )"
  },

  link: {
    color: "#ffffff",
    textDecoration: "none",
    textAlign: "center",
    display: "block",
    width: "50%",
    marginLeft: "25%",
    marginRight: "25%",
    borderBottom: "1px solid #ffffff"
  },
  media: {
    height: 140,
  },


  instalacion: {
      height: 100+"vh",
      backgroundSize: "cover",
      alignItems: "center",
      marginTop:"10px",
      padding: "64px 0 45px 0"
  },

  instalacionCard: {
    marginTop:"20px",
    width: 300
  },

  instalacioncontainer: {
    flexGrow: 1,
  }


});



class Calendar extends React.Component  {
  constructor(props) {
      super(props);
      this.state = {deporte: [], instalacion: [], actividad: []};
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    
    //let id= this.props.match.params.id;
    console.log(this.props);
    console.log(this.props.match.url);
  
    let deporte_id= this.props.match.params.did;
    let instalacion_id = this.props.match.params.iid;
    let actividad_id = this.props.match.params.aid;

    let deporte = await API.get('/deportes/'+deporte_id, config);
    this.setState({deporte: deporte.data});
  
   
   let instalacion = await API.get('/instalaciones/'+instalacion_id, config);
    this.setState({instalacion: instalacion.data});
   

   let actividad = await API.get('/actividades/'+actividad_id, config);
    
    this.setState({actividad: actividad.data});
    console.log(this.state );

    // Comprobar si esta sancionado el usuario.
    
 
  };

  render() {
      const { classes } = this.props;
      const { t } = this.props;
      return (
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.contenedor} >
            <Grid item xs={12} sm={2} style={{overflow:"hidden", height:"100vh"}}>
              <div className={classes.cuadro} style={{backgroundImage:"linear-gradient(rgba(0,0,0,.65), rgba(0,0,0,.65)), url(/images/"+this.state.deporte.imagen+")"}}>
                <NavLink className={classes.link} to={`/deporte/${this.state.deporte.id}`} >{t(this.state.deporte.nombre)}</NavLink>  
                <NavLink className={classes.link} to={`/deporte/${this.state.deporte.id}/instalacion/${this.state.instalacion.id}`} >{t(this.state.instalacion.nombre)}</NavLink>  
                <NavLink className={classes.link} style={{borderBottom: "none"}} to={`/deporte/${this.state.deporte.id}/instalacion/${this.state.instalacion.id}/actividad/${this.state.actividad.id}`} >{t(this.state.actividad.nombre)}</NavLink>  
                <div className={classes.icon} style={{backgroundImage:"url(/images/"+this.state.actividad.imagen+")"}}></div>
              </div>
            </Grid> 
           
            <Grid item xs={12} sm={10} style={{overflow:"hidden"}} className={classes.instalacion}>
              <Grid container className={classes.instalacioncontainer} justify="center" spacing={2}>
              
                <CalendarBooking actividadId={this.state.actividad.id} actividad={this.state.actividad} deporte={this.state.deporte} instalacion={this.state.instalacion}></CalendarBooking>

              </Grid>
            </Grid> 

        </Grid>

      </div>

    );
  }
};


export default withTranslation()(withStyles(useStyles)(Calendar));









