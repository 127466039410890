import React from 'react';
import API from "../utils/API";
import Grid from '@material-ui/core/Grid';
import {NavLink} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

//card

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({
    root: {
      position: "relative",
      height: "100vh",
      overflow: "hidden"
    },
    deporte: {
        height: 100+"vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "#ffffff",
        fontSize: 40+"px",
        textShadow: "2px 2px 4px #000000",
        paddingTop: 50+"vh",
        textAlign: "center",
        tarnsform: "scale(1)",
        transition: "all .25s",
        display: "block",
        textDecoration: "none",
        "&:hover": {
            transform: "scale(1.1)",
            transition: "all .25s"
        },
    },

    instalacion: {
        height: 100+"vh",
        backgroundSize: "cover",
        alignItems: "center",
        marginTop:"10px",
        padding: "64px 0 45px 0"
    },

    instalacionCard: {
      marginTop:"20px",
      width: 300
    },

    instalacioncontainer: {
      flexGrow: 1,
    }

});



class Instalaciones extends React.Component  {
  constructor(props) {
      super(props);
      this.state = {deporte: [], instalaciones: []};
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    console.log(this.props);
    console.log(this.props.match.params.id);
    let id= this.props.match.params.id;
    let deporte = await API.get('/deportes/'+id, config);
    this.setState({deporte: deporte.data});


    let instalaciones = await API.get('instalaciones/deporte/'+id, config);
    this.setState({instalaciones: instalaciones.data});

 
 
  };

  render() {
      const { classes } = this.props;
      const { t } = this.props;
      return (
      <div className={classes.root}>
        <Grid container spacing={0}>

            <Grid item xs={12} sm={4} style={{overflow:"hidden"}}>
                <NavLink 
                  to={`/deporte/${this.state.deporte.id}`} 
                  className={classes.deporte} 
                  style={{backgroundImage:"linear-gradient(rgba(0,0,0,.65), rgba(0,0,0,.65)), url(/images/"+this.state.deporte.imagen+")"}}>
                  {t(this.state.deporte.nombre)}
                        
                </NavLink>
            
            </Grid>
              
            <Grid item xs={12} sm={8} style={{overflow:"auto"}} className={classes.instalacion} >
            <Grid container className={classes.instalacioncontainer} justify="center" spacing={2}>
              { this.state.instalaciones.map(instalacion => 
                  <Grid item  key={instalacion.id}>
                    <Card className={classes.instalacionCard}>
                      <CardMedia
                        style={{height: 140}}
                        image= {"/images/"+instalacion.imagen}
                        title="Instalación"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                        {t(instalacion.nombre)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('Dirección')}: {instalacion.direccion}<br/>
                          {t('Teléfono')}: {instalacion.telefono}<br/>
                          {instalacion.otros}
                        </Typography>
                      </CardContent>
                    <CardActions>
                      <Button size="small" color="primary" onClick={()=>{this.props.history.push(`/deporte/${this.state.deporte.id}/instalacion/${instalacion.id}`)}}>
                      {t('Seleccionar')}
                      </Button>
                    </CardActions>
                  </Card>
                  </Grid>
              )}
              </Grid>
            </Grid>

        </Grid>

      </div>

    );
  }
};


export default withTranslation()(withStyles(useStyles)(Instalaciones));









