//import base64 from 'react-native-base64';

export const checkAuth = ()=>{
    let token = localStorage.getItem("token");
    if(token !== undefined && token!=="" && token!==null)
        //let userdata = JSON.parse(base64.decode(token.split(".")[1]));
        return true;
    else
        return false;

}

export const parseJwt = (token)=> {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  };

export const getUser = ()=>{
    let token = localStorage.getItem("token");
    console.log(token);
    if(token !== undefined && token!=="" && token!==null){
        var userdata = parseJwt(token);
        return userdata;
    }else
        return null;

}

export const getHeaderAuth = ()=>{
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return config;
}

export const cleanAuth = () => {
    localStorage.removeItem("token");
}