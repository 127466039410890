
/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.getWeekNumber = function(){
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
};

export const ReservaTitulo = (reserva) => {
    let descripcion = reserva.Actividade.nombre;
    if(reserva.Horario.descripcion){
      let d= reserva.Horario.descripcion.split("/");
      if(d.length>1){
          let fecha = reserva.fecha;
          if(typeof fecha === 'string')
            fecha = new Date(fecha);
          let w= fecha.getWeekNumber();
          descripcion=d[w%d.length];
      }else{
          descripcion=d[0];
      }
    }
    return descripcion;
}