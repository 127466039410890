import React from 'react';
import { connect } from 'react-redux';
import { cleanAuth } from '../utils/Auth';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LockIcon from '@material-ui/icons/Lock';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Notifications from '@material-ui/icons/Notifications';
import { getUser} from '../utils/Auth';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, red } from '@material-ui/core/colors';
import { parseSpainFormatDate } from '../utils/Dates';

import API from "../utils/API";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    position:"fixed",
    top:0,
    width: "100vw",
    zIndex: 1
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  right: {
    textAlign: "right"
  },
  typography: {
    padding: theme.spacing(2),
  },
  warning: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  error: {
    color: theme.palette.getContrastText(red[400]),
    backgroundColor: red[400],
  },
  critical: {
    color: theme.palette.getContrastText(red[900]),
    backgroundColor: red[900],
  },
});

function mapStateToProps(state) {
  return {
    loggedIn: state.loggedIn
  };
}

class Navegacion extends React.Component  {
  constructor(props) {
      super(props);
      this.state = {openPopup: false, anchorEl: null, reservas: [], sanciones: [], notificaciones:{}};
      this.logout = this.logout.bind(this);
      this.handleClose= this.handleClose.bind(this);
      this.handleClick= this.handleClick.bind(this);
      this.updateData = this.updateData.bind(this);
  }


  async updateData(){
    if(this.props.loggedIn){
      let token = localStorage.getItem("token");
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
      
      let userdata = getUser(token);
      let perfil = await API.get('/user/profile/'+userdata.username, config);
      let sanciones = await API.get('/sanciones/'+perfil.data.id, config);
      let reservas = await API.get('/reservas/user/noconf/'+perfil.data.id, config);
      this.setState({reservas: reservas.data, sanciones:sanciones.data});
      
      // Agrupamos las reservas para extraer las notificaciones
      let notificaciones_por_actividad={};
      for(let i of reservas.data){
        if(notificaciones_por_actividad[i.ActividadeId]===undefined){
          notificaciones_por_actividad[i.ActividadeId]={actividad:i.Actividade, reservas:[]};
        }
        notificaciones_por_actividad[i.ActividadeId]["reservas"].push(i);
      }
      console.log(notificaciones_por_actividad);
      this.setState({notificaciones: notificaciones_por_actividad});
    }
  }

  async componentDidUpdate(prevProps) {
    if(this.props.loggedIn !== prevProps.loggedIn){
      await this.updateData();
    }
  }
    async componentDidMount() {
      await this.updateData();
  }

  logout(event) {
    event.preventDefault();
    cleanAuth();
    this.props.dispatch({ type: "LOGOUT" });
  }

  handleClick(event){
    this.setState({anchorEl: event.currentTarget, openPopup: true});
  }

  handleClose(event){
    this.setState({anchorEl: null, openPopup: false});
  }

  getAusenciasAvt(num){
    if(num>3)
      return "+3";
    else
      return num;
  }
  getAusenciasCls(num){
    if(num>=3)
      return "critical";
    else if(num===2)
      return "error";
    else
      return "warning";
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    if(this.props.loggedIn){
      let user = getUser();
      if (user.role===0 || user.role===3 || user.role===5){
        // Usuario
        return (
          <div className={classes.root}>
            
            <AppBar position="static">
              <Toolbar className={classes.bar }>
                <Grid container>
                  <Grid item xs={6}>
                    <Button color="inherit" href= "/deportes" startIcon={<AddIcon />}>{t('Nueva reserva')}</Button>
                    <Button color="inherit" href="/misreservas" startIcon={<DehazeIcon />}>{t('Mis reservas')}</Button>
                  </Grid>
                  <Grid item xs={6} className={classes.right}>
                    {(Object.entries(this.state.notificaciones).length>0 || this.state.sanciones.length>0) &&
                      <IconButton color="inherit" aria-label="Notificaciones" component="span" onClick={this.handleClick}>
                        <Badge badgeContent={Object.entries(this.state.notificaciones).length+this.state.sanciones.length} color="error">
                          <Notifications />
                        </Badge>
                      </IconButton>
                    }

                    <Button color="inherit" href="/perfil" startIcon={<AccountCircle />}>{t('Usuario')}</Button>
                    <Button onClick={this.logout} color="inherit" startIcon={<ExitToApp />}>{t('Cerrar Sesión')}</Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            {(Object.entries(this.state.notificaciones).length>0 || this.state.sanciones.length > 0) &&
            <Popover 
            open={this.state.openPopup}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
              {Object.entries(this.state.notificaciones).length>0 &&
              <>
              <Typography className={classes.typography}>Ausencias en los últimos 30 días: </Typography>
              <List dense={true}>
              { Object.entries(this.state.notificaciones).map((notificacion) => 
                <ListItem>
                  <ListItemAvatar>
                  <Avatar className={classes[this.getAusenciasCls(notificacion[1].reservas.length)]}>
                    {this.getAusenciasAvt(notificacion[1].reservas.length)}
                  </Avatar>
                </ListItemAvatar>
                  <ListItemText
                    primary={notificacion[1].actividad.nombre}
                    secondary={notificacion[1].actividad.Instalacione.nombre} 
                  />
                </ListItem>
              )}
              </List>
              </>
              }


              {this.state.sanciones.length > 0 &&
              <>
              <Typography className={classes.typography}>Sanciones: </Typography>
              <List dense={true}>
              { this.state.sanciones.map((sancion) => 
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.error}>
                      !
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={parseSpainFormatDate(sancion.dia_inicio)+" hasta "+parseSpainFormatDate(sancion.dia_fin)}
                    secondary={sancion.Actividade.nombre+" ("+sancion.Actividade.Instalacione.nombre+")"} 
                  />
                </ListItem>
              )}
              </List>
              </>
              }
              
          </Popover>
          }
          </div>
        );
      }else if(user.role===1){
        // Administrador
        return (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar className={classes.bar }>
                <Grid container>
                  <Grid item xs={6}>
                    <Button color="inherit" href= "/adm/reservas" startIcon={<AddIcon />}>{t('Reservas')}</Button>
                    <Button color="inherit" href="/adm/users" startIcon={<DehazeIcon />}>{t('Usuarios')}</Button>
                    <Button color="inherit" href="/adm/colegios" startIcon={<DehazeIcon />}>{t('Colegios')}</Button>
                    <Button color="inherit" href="/adm/cierres" startIcon={<LockIcon />}>{t('Cierres')}</Button>
                  </Grid>
                  <Grid item xs={6} className={classes.right}>
                    <Button color="inherit" href="/perfil" startIcon={<AccountCircle />}>{t('Usuario')}</Button>
                    <Button onClick={this.logout} color="inherit" startIcon={<ExitToApp />}>Cerrar Sesión</Button>
                  </Grid>
                </Grid>
              </Toolbar>

            </AppBar>
          </div>
        );
      }else{
        // Conserje
        return (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar className={classes.bar }>
                <Grid container>
                  <Grid item xs={6}>
                    <Button color="inherit" href= "/adm/reservas" startIcon={<AddIcon />}>{t('Reservas')}</Button>
                    <Button color="inherit" href="/adm/users" startIcon={<DehazeIcon />}>{t('Usuarios')}</Button>
                  </Grid>
                  <Grid item xs={6} className={classes.right}>
                    <Button color="inherit" href="/perfil" startIcon={<AccountCircle />}>{t('Usuario')}</Button>
                    <Button onClick={this.logout} color="inherit" startIcon={<ExitToApp />}>Cerrar Sesión</Button>
                  </Grid>
                </Grid>
              </Toolbar>

            </AppBar>
          </div>
        );
      }
    }else{
      //return <Redirect to={{ pathname: '/', state: { from: this.props.location }}} />;
      return <div></div>;
    }
  }
}


export default withTranslation()(withStyles(useStyles)(connect(mapStateToProps)(Navegacion)));