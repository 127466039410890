import React from 'react';
import API from "../utils/API";
import {getHeaderAuth} from "../utils/Auth";
import Grid from '@material-ui/core/Grid';
import {NavLink} from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
//card

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = theme => ({
  root: {
    position: "relative",
    height: "100vh",
    overflow: "hidden"
  },
  contenedor: {
    height: 100+"vh",
  },

    cuadro: {
        height: "calc( 50vh + 115px)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "#ffffff",
        fontSize: 40+"px",
        textShadow: "2px 2px 4px #000000",
        textAlign: "center",
        tarnsform: "scale(1)",
        transition: "all .25s",
        textDecoration: "none",
        paddingTop: "calc( 50vh - 115px )"
        /*"&:hover": {
            transform: "scale(1.1)",
            transition: "all .25s"
        },*/
    },

    card: {
      marginTop:"20px",
 
      width: "40vw", 
      height: "200px",
      boxShadow: "0px -2px 10px #eee",
  },

  icon: {
    backgroundSize: "cover",
    borderRadius: "50%",
    width: "150px",
    height: "150px",
    marginLeft: "calc( 50% - 75px )"
  },

  link: {
    color: "#ffffff",
    textDecoration: "none",
    textAlign: "center",
    display: "block",
    width: "50%",
    marginLeft: "25%",
    marginRight: "25%",
    borderBottom: "1px solid #ffffff"
  },
  media: {
    height: 140,
  },


  instalacion: {
      height: 100+"vh",
      backgroundSize: "cover",
      alignItems: "center",
      marginTop:"10px",
      padding: "64px 0 45px 0"
  },

  instalacionCard: {
    marginTop:"20px",
    width: 300
  },

  instalacioncontainer: {
    flexGrow: 1,
  }


});



class Actividades extends React.Component  {
  constructor(props) {
      super(props);
      this.state = {deporte: [], instalacion: [], actividades: []};
      this.seleccion = this.seleccion.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    
    //let id= this.props.match.params.id;
    console.log(this.props);
    console.log(this.props.match.url);
  
    let deporte_id= this.props.match.params.did;
    let instalacion_id = this.props.match.params.iid;
    let parent_id = this.props.match.params.pid;
    
    let deporte = await API.get('/deportes/'+deporte_id, config);
    this.setState({deporte: deporte.data});
  
   
   let instalacion = await API.get('/instalaciones/'+instalacion_id, config);
    this.setState({instalacion: instalacion.data});
   
    let actividades=[];
    if(parent_id===undefined)
      actividades = await API.get('/actividades/deporte/'+deporte_id+'/instalacion/'+instalacion_id, config);
    else
      actividades = await API.get('/actividades/deporte/'+deporte_id+'/instalacion/'+instalacion_id+"/"+parent_id, config);
    
    this.setState({actividades: actividades.data});
    console.log(this.state );
 
  };

  async seleccion(actividad) {
    if(actividad.haschilds)
    {
      this.props.history.push(`/deporte/${this.state.deporte.id}/instalacion/${this.state.instalacion.id}/${actividad.id}`);
      let actividades = await API.get('/actividades/deporte/'+this.state.deporte.id+'/instalacion/'+this.state.instalacion.id+"/"+actividad.id, getHeaderAuth());
      this.setState({actividades: actividades.data});
    }else{
      this.props.history.push(`/deporte/${this.state.deporte.id}/instalacion/${this.state.instalacion.id}/actividad/${actividad.id}`)
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Hack: setTimeout delays click until end of current
      // event loop to ensure new screen has mounted.
      let deporte_id= this.props.match.params.did;
      let instalacion_id = this.props.match.params.iid;
      let parent_id = this.props.match.params.pid;
      let actividades=[];
      if(parent_id===undefined)
        actividades = await API.get('/actividades/deporte/'+deporte_id+'/instalacion/'+instalacion_id, getHeaderAuth());
      else
        actividades = await API.get('/actividades/deporte/'+deporte_id+'/instalacion/'+instalacion_id+"/"+parent_id, getHeaderAuth());
      
      this.setState({actividades: actividades.data});
    }
  }

  render() {
      const { classes } = this.props;
      const { t } = this.props;
      return (
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.contenedor} >
            <Grid item xs={12} sm={4} style={{overflow:"hidden", height:"100vh"}}>
              <div className={classes.cuadro} style={{backgroundImage:"linear-gradient(rgba(0,0,0,.65), rgba(0,0,0,.65)), url(/images/"+this.state.deporte.imagen+")"}}>
                <NavLink className={classes.link} to={`/deporte/${this.state.deporte.id}`} >{t(this.state.deporte.nombre)}</NavLink>  
                <NavLink className={classes.link} style={{borderBottom: "none"}} to={`/deporte/${this.state.deporte.id}`} >{t(this.state.instalacion.nombre)}</NavLink>  
                <div className={classes.icon} style={{backgroundImage:"url(/images/"+this.state.instalacion.imagen+")"}}></div>
              </div>
            </Grid> 
           
            <Grid item xs={12} sm={8} style={{overflow:"auto"}} className={classes.instalacion}>
              <Grid container className={classes.instalacioncontainer} justify="center" spacing={2}>
              { this.state.actividades.map(actividad => 
                 <Grid item  key={actividad.id}>
                 <Card className={classes.instalacionCard}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={"/images/"+actividad.imagen}
                      title={actividad.nombre}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {t(actividad.nombre)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {t(actividad.detalles)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary"  onClick={()=>{this.seleccion(actividad)}}>
                    {t("Seleccionar")}
                    </Button>
                  </CardActions>
                </Card>
                 </Grid>
              )}
              </Grid>
            </Grid> 

        </Grid>

      </div>

    );
  }
};


export default withTranslation()(withStyles(useStyles)(Actividades));









