import React from 'react';
import API from "../../utils/API";
//import {getHeaderAuth, getUser} from "../../utils/Auth";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import  Sancion  from './sancion';
import Grid from '@material-ui/core/Grid';
import { getHeaderAuth } from '../../utils/Auth';

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: "blur(10px)",
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: "4px"
  },
  root:{
    padding: "150px 0 45px 0"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
      width:100+"vw",
      height:100+"vh",
      background: "url('/images/backgrounds/1.jpeg') no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "auto",
      padding: "40px 0"
  },
  cardsContainer: {
    margin: "auto",
    display: "flex"
  },
  
});

function mapStateToProps(state) {
  return {
    loggedIn: state.loggedIn,
    selUser: state.selUser
  };
}


class Sanciones extends React.Component{

  constructor(props) {

      super(props);
      this.state = {sanciones:[]};
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onDelete = this.onDelete.bind(this);
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
    let uid= this.props.match.params.uid;
    let sanciones = await API.get('/sanciones/'+uid, config);
    this.setState({sanciones: sanciones.data});
    
  }

  async onDelete(id){
    console.log("SE BORRA ELEMENTO CON ID: "+id);
    let result = await API.delete('/sanciones/'+id, getHeaderAuth());
    if(result.data.status==="OK"){
      this.setState({sanciones: this.state.sanciones.filter(function(sancion) { 
        return sancion.id !== id 
      })});
    }else{
      alert(result.data.message);
    }
  }
  

  render() {
    //let user = getUser();
    
    const  classes  = this.props.classes;
    //const { t } = this.props;
    return (

      
        <div className={classes.root}>

          <Container  maxWidth={false}>
          {this.state.sanciones.length > 0 && 
            <h1>El usuario tiene las siguientes sanciones actualmente activas:</h1>
          }
          <Grid container spacing={3}>
          {this.state.sanciones.map((sancion) => 
            <Grid key={sancion.id} item xs={12} sm={6} md={4} lg={3}> <Sancion sancion={sancion} onDelete={this.onDelete} /> </Grid>
          )}
          </Grid>
          {this.state.sanciones.length === 0 && 
            <h1>Este usuario no tiene ninguna sanción actualmente</h1>
          }
          </Container>
        </div>
  
    );
  };
  
}

export default withTranslation()(withRouter(withStyles(styles)(connect(mapStateToProps)(Sanciones))))