import { forwardRef } from 'react';
import React from 'react';
import API from "../../utils/API";
import {getHeaderAuth} from "../../utils/Auth";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { withTranslation } from 'react-i18next';
import { parseSpainFormatDate } from '../../utils/Dates';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import exportFromJSON from 'export-from-json';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: "blur(10px)",
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: "4px"
  },
  root:{
    padding: "70px 0 45px 0"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
      width:100+"vw",
      height:100+"vh",
      background: "url('/images/backgrounds/1.jpeg') no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "auto",
      padding: "40px 0"
  },
  cardsContainer: {
    margin: "auto",
    display: "flex"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details:{
    display: "block"
  }
});

class Reservas extends React.Component{

  constructor(props) {
      super(props);
      let fecha_inicio = new Date();
      //fecha_inicio.setHours(1,0,0);
      let fecha_fin = new Date();
      fecha_fin.setHours(23,59,59);
      this.state = {reservas:[], instalaciones:{}, deportes:{}, actividades:[], actividad:null, centro:null, instalacion:null, fecha_inicio: fecha_inicio, fecha_fin: fecha_fin, hora_inicio: null, hora_fin:null, num_reservas: null, nombre: null, apellidos: null, dni: null, telefono: null, email: null, now: new Date()};
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onDelete = this.onDelete.bind(this);
      this.onConfirm = this.onConfirm.bind(this);
      this.applyFilter = this.applyFilter.bind(this);
      this.cleanFilter = this.cleanFilter.bind(this);
  }
  
  async cleanFilter(event) {
    event.preventDefault();
    let fecha_inicio = new Date();
    //fecha_inicio.setHours(1,0,0);
    let fecha_fin = new Date();
    fecha_fin.setHours(23,59,59);
    this.setState({
      actividad: "",
      centro: null,
      instalacion: null, 
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin,
      hora_inicio: null,
      hora_fin: null,
      num_reservas: "",
      nombre: "",
      apellidos: "",
      dni: "",
      telefono: "",
      email: "",
    },()=>{this.applyFilter(event)});
  }

  async applyFilter(event) {
    if(event!==undefined)
      event.preventDefault();
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    let data= {
      actividad: this.state.actividad,
      centro: this.state.centro,
      instalaion: this.state.instalacion, 
      fecha_inicio: this.state.fecha_inicio,
      fecha_fin: this.state.fecha_fin,
      hora_inicio: (this.state.hora_inicio)?this.state.hora_inicio.toLocaleString('en-US'):null,
      hora_fin: (this.state.hora_fin)?this.state.hora_fin.toLocaleString('en-US'):null,
      num_reservas: this.state.num_reservas,
      nombre: this.state.nombre,
      apellidos: this.state.apellidos,
      dni: this.state.dni,
      telefono: this.state.telefono,
      email: this.state.email
    }
    let reservas = await API.post('/reservas/filter', data, config);
    this.setState({reservas: reservas.data});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    //let userdata = JSON.parse(base64.decode(token.split(".")[1]));
    //let userdata = getUser(token);
    
    this.applyFilter();
    // let reservas = await API.get('/reservas/', config);
    // this.setState({reservas: reservas.data});
    
    let instalaciones = await API.get('/instalaciones/', config);
    let idata = {};
    for (let i of instalaciones.data){
        idata[i.id]= i.nombre;
    }
    this.setState({instalaciones: idata});

    let deportes = await API.get('/deportes/', config);
    let ddata = {};
    for (let i of deportes.data){
        ddata[i.id]= i.nombre;
    }
    this.setState({deportes: ddata});


    let actividades = await API.get('/actividades/group', config);
    this.setState({actividades: actividades.data});
    
  }

  async onDelete(id){
    console.log("SE BORRA ELEMENTO CON ID: "+id);
    let result = await API.delete('/reservas/'+id, getHeaderAuth());
    if(result.data.status==="OK"){
      this.setState({reservas: this.state.reservas.filter(function(reserva) { 
        return reserva.id !== id 
      })});
    }else{
      alert(result.data.message);
    }
  }

  async onConfirm(data){
    let result = await API.get('/reservas/confirmar/'+data.id, getHeaderAuth());
    if(result.data.status==="ok"){
      let reservas= this.state.reservas;
      for(let i=0; i<reservas.length; i++){
        if(reservas[i].id===data.id){
          reservas[i].reserva_confirmada=result.data.reserva_status;
        }
      }
      this.setState({reservas: reservas});
    }else{
      console.log("Imposible confirmar");
      alert("No se ha podido conrirmar la reserva. "+result.data.message);
    }
  }

  render() {
  
    const  classes  = this.props.classes;
    //const { t } = this.props;
    let user_roles= {3:"Usuario sin limites", 0:"Usuario", 1:"Administrador", 2:"Operario"};

    return (

      
        <div className={classes.root}>

          <Container maxWidth={false}>

          <div style={{padding: '20px 0'}}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Opciones de filtrado</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    
                      <Typography>Filtrar por:</Typography>
                      <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                          <FormControl fullWidth={true} >
                            <InputLabel htmlFor="actividad-label">Actividad</InputLabel>
                            <Select fullWidth={true} 
                            labelId="actividad-label"
                            id="actividad"
                            name="actividad"
                            value={this.state.actividad}
                            label="Actividad" 
                            onChange={(event)=>{this.setState({'actividad':event.target.value})}}>
                              { this.state.actividades.map((actividad) =>
                                <MenuItem value={actividad.nombre}>{actividad.nombre}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <FormControl fullWidth={true} >
                            <InputLabel htmlFor="centro-label">Centro</InputLabel>
                            <Select fullWidth={true} 
                            labelId="centro-label"
                            id="centro"
                            name="centro"
                            value={this.state.centro}
                            label="Centro" 
                            onChange={(event)=>{this.setState({'centro':event.target.value})}}>
                              { Object.keys(this.state.instalaciones).map((key,instalacion) =>
                                <MenuItem value={this.state.instalaciones[key]}>{this.state.instalaciones[key]}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                        <FormControl fullWidth={true} >
                            <InputLabel htmlFor="instalacion-label">Instalación</InputLabel>
                            <Select fullWidth={true} 
                            labelId="instalacion-label"
                            id="instalacion"
                            name="instalacion"
                            value={this.state.instalacion}
                            label="Instalación" 
                            onChange={(event)=>{this.setState({'instalacion':event.target.value})}}>
                              { Object.keys(this.state.deportes).map((key,deporte) =>
                                <MenuItem value={this.state.deportes[key]}>{this.state.deportes[key]}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                          <TextField fullWidth={true} 
                          id="n_reservas"
                          name="num_reservas" 
                          value={this.state.num_reservas}
                          label="Nº de reservas" 
                          onChange={this.handleInputChange} />
                        </Grid>

                        <Grid item xs={6} sm={3}>
                          <TextField fullWidth={true} 
                          id="nombre"
                          name="nombre"
                          value={this.state.nombre} 
                          label="Nombre" 
                          onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField fullWidth={true} 
                          id="apellidos"
                          name="apellidos"
                          value={this.state.apellidos} 
                          label="Apellidos" 
                          onChange={this.handleInputChange} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField fullWidth={true} 
                          id="dni"
                          name="dni"
                          value={this.state.dni} 
                          label="DNI" 
                          onChange={this.handleInputChange} />
                        </Grid>

                        <Grid item xs={6} sm={3}>
                          <TextField fullWidth={true} 
                          id="telefono" 
                          name="telefono"
                          value={this.state.telefono}
                          label="Teléfono" 
                          onChange={this.handleInputChange} />
                        </Grid>
                        
                        <Grid item xs={6} sm={3}>
                          <TextField fullWidth={true} 
                          id="email" 
                          name="email"
                          value={this.state.email}
                          label="Correo electrónico" 
                          onChange={this.handleInputChange} />
                        </Grid>

                        <Grid item xs={6} sm={3}></Grid>
                        <Grid item xs={6} sm={3}></Grid>
                        <Grid item xs={6} sm={3}></Grid>

                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                          <Grid item xs={6} sm={3}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="fecha_inicio"
                            label="Fecha inicio"
                            format="dd/MM/yyyy"
                            value={this.state.fecha_inicio}
                            onChange={(val)=>{this.setState({fecha_inicio: val})}}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="fecha_fin"
                            label="Fecha fin"
                            format="dd/MM/yyyy"
                            value={this.state.fecha_fin}
                            onChange={(val)=>{this.setState({fecha_fin: val})}}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="hora_inicio"
                              label="Hora inicio"
                              ampm={false}
                              value={this.state.hora_inicio}
                              onChange={(val)=>{this.setState({hora_inicio: val})}}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                            />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                            <KeyboardTimePicker
                              margin="normal"
                              id="hora_fin"
                              label="Hora fin"
                              ampm={false}
                              value={this.state.hora_fin}
                              onChange={(val)=>{this.setState({hora_fin: val})}}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                            />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        
                      </Grid>
                      </Box>
                      <Box>
                        <Button variant="contained" onClick={this.applyFilter} color="primary" style={{marginRight:"10px"}}>Aplicar filtro</Button>
                        <Button variant="contained" onClick={this.cleanFilter} color="primary">Limpiar filtros</Button>
                      </Box>
                
                  </AccordionDetails>
              </Accordion>
              </div>
            <MaterialTable
            icons={tableIcons}
            columns={[
              { title: "Actividad", field: "Actividade.nombre" },
              { title: "Centro", field: "Actividade.InstalacioneId", lookup: this.state.instalaciones },
              { title: "Instalación", field: "Actividade.DeporteId", lookup: this.state.deportes },
              { title: "Fecha", field: "fecha", render: rowData=> parseSpainFormatDate(rowData.fecha)},
              { title: "Descripción Slot", field: "Horario.descripcion"},
              { title: "Hora Inicio", field: "Horario.hora_inicio"},
              { title: "Hora Fin", field: "Horario.hora_fin"},
              { title: "Nº res.", field: "num_reservas", type: "numeric" },
              { title: "Nombre", field: "User.nombre"},
              { title: "Apellidos", field: "User.apellidos"},
              { title: "Email", field: "User.email"},
              { title: "DNI", field: "User.dni"},
              { title: "Teléfono", field: "User.telefono"},
              { title: "Correo electrónico", field: "User.email"},
              { title: "Reserva realizada por", field: "realizada_por", lookup: user_roles},
              //{ title: "Email", field: "User.email"},
            ]}
            data={this.state.reservas}
            //onRowClick={((evt, selectedRow) => console.log(new Date(selectedRow.fecha+" "+selectedRow.Horario.hora_inicio)))}
            onChangeRowsPerPage={()=>{
              //document.body.scrollTop = 0; // For Safari
              //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
              setTimeout(()=>{window.scrollTo(0,0)},1000);
              console.log("Go to top");
            }}
            onChangePage={()=>{
              window.scrollTo(0,0);
              console.log("Go to top");
            }}
            actions={[
              rowData => (
                 {
                     icon: Check,
                     tooltip: 'Confirmar/Desconfirmar reserva',
                     onClick: (event, rowData) => this.onConfirm(rowData),
                     //disabled: rowData.reserva_confirmada===1
                 }
                )
              ]}
            editable={{
              onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                  //const dataDelete = [...this.state.reservas];
                  //const index = oldData.tableData.id;
                  this.onDelete(oldData.id).then(()=>{
                    //dataDelete.splice(index, 1);
                    //this.setState({reservas:dataDelete});
                    resolve();                  
                  })
              }),
            }}
            localization={{ 
              body: { 
                addTooltip: "Añadir",
                deleteTooltip: "Borrar",
                editTooltip: "Editar",
                editRow: { 
                  deleteText: '¿Deseas borrar la reserva seleccionada?',
                  cancelTooltip: "Cancelar",
                  saveTooltip: "Guardar"
                } 
              }, 
              header:{
                actions: "Acciones"
              },
              toolbar: {
                searchTooltip: "Buscar", 
                searchPlaceholder:"Buscar",
                exportTitle: "Exportar",
                exportAriaLabel: "Exportar",
                exportName: "Exportar como"
              }, 
              pagination: {labelRowsSelect: "filas"} }}
            options={{
              exportButton: true,
              exportCsv: (columns, data) => {
                let export_data=[];
                for(let d  of data){
                  let r={};

                  r["Reserva confirmada"]="No";
                  if(d.reserva_confirmada)
                    r["Reserva confirmada"]="Si";
                                    
                  for(let c of columns){
                    let val = c.field.split('.').reduce(function(m, n){ return m[n] }, d);
                    if(c.lookup === undefined)
                      r[c.title]=val;
                    else
                      r[c.title]=c.lookup[val];
                  }
                  export_data.push(r);
                }
                //console.log(export_data);
                //alert('You should develop a code to export ' + data.length + ' rows');
                exportFromJSON({ data:export_data, filename:"export", exportType:"xls", withBOM:true });
              },
              pageSize: 30,
              pageSizeOptions: [30,50,100,500, { value: -1, label: "All" }],
              //filtering: true,
              headerStyle: {
                  padding: "12px",
                    fontSize: "0.8em",
                },
              cellStyle: {
                padding: "12px",
                fontSize: "0.8em",
              },
              rowStyle: rowData => ({
                color: (rowData.reserva_confirmada===1) ? '#080' : '#000',
                backgroundColor: (this.state.now >= new Date(rowData.fecha+" "+rowData.Horario.hora_inicio)) ? '#EEE' : '#FFF',
                opacity: (this.state.now >= new Date(rowData.fecha+" "+rowData.Horario.hora_inicio)) ? '0.35' : '1'
              })
            }}
            title="Reservas"
          />
          </Container>
        </div>
  
    );
  };
  
}

export default withTranslation()(withRouter(withStyles(styles)(Reservas)))