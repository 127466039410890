import React from 'react';
import API from "../utils/API";
import {getUser} from "../utils/Auth";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { getHeaderAuth } from '../utils/Auth';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: "blur(10px)",
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: "4px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
      width:100+"vw",
      height:100+"vh",
      background: "url('/images/backgrounds/1.jpeg') no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "auto",
      padding: "40px 0"
  }
});

class Perfil extends React.Component{

  constructor(props) {

      super(props);
      this.state = {id:0, current_pass: '', pass: '', passwordRepeat:'', id_image:(Math.floor(Math.random() * 6) + 1)};
      this.handleInputChange = this.handleInputChange.bind(this);
      this.guardar = this.guardar.bind(this);
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async guardar(event) {
    event.preventDefault();
    this.setState({"mensaje":""});
    this.setState({"mensaje_error":""});
    try{
      let result = await API.put('/user/pass', this.state, getHeaderAuth());
      this.setState({"status":result.data.status});
      if(this.state.status==="ok"){
        this.setState({"mensaje":"Cambios correctamente guardados"});
      }else{

        this.setState({"mensaje_error":result.data.message});
      }
    }catch(e)
    {
      this.setState({"status":"ko"});
      this.setState({"mensaje_error":"Error de conexión"});
    }
  }

  async componentDidMount() {
    let token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
    let userdata = getUser(token);
    
    let perfil = await API.get('/user/profile/'+userdata.username, config);
    this.setState({id: perfil.data.id});
    
  }

  render() {
  
    const  classes  = this.props.classes;
    const { t } = this.props;
    return (

      <div className={classes.background} style={{backgroundImage: "url('/images/backgrounds/"+this.state.id_image+".jpeg')"}}>
              <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    {t("Cambiar contraseña")}
                    </Typography>
                    <form className={classes.form} noValidate>
                      
                      <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="current_pass"
                          label={t('Contraseña actual')}
                          type="password"
                          id="current_pass"
                          error={this.state.current_pass.length > 0 && this.state.current_pass.length < 5}
                          //helperText={this.state.pass.length < 5? "Mínimo 5 caracteres":""}
                          autoComplete={t('Contraseña actual')}
                          onChange={this.handleInputChange}
                      />

                      <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="pass"
                          label={t('Password')}
                          type="password"
                          id="pass"
                          error={this.state.pass.length > 0 && this.state.pass.length < 5}
                          //helperText={this.state.pass.length < 5? "Mínimo 5 caracteres":""}
                          autoComplete="password"
                          onChange={this.handleInputChange}
                      />
                          <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          error={this.state.pass !== this.state.passwordRepeat}
                          helperText={this.state.pass !== this.state.passwordRepeat ? 'Las contraseñas son diferente' : ' '}
                          name="passwordRepeat"
                          label={t("Repite Password")}
                          type="password"
                          id="passwordRepeat"
                          autoComplete="Repite Password"
                          onChange={this.handleInputChange}
                      />
                        
                      <p style={{color:"#a00"}}>{this.state.mensaje_error}</p>
                      <p style={{color:"#0a0"}}>{this.state.mensaje}</p>

                        <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={this.guardar}
                        >
                            {t("Guardar cambios")} 
                        </Button>

                    </form>
                </div>
            </Container>

        </div>
    );
  };
  
}

export default withTranslation()(withRouter(withStyles(styles)(Perfil)))