import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    root: {
      flexGrow: 1,
    },
    politicaContenedor: {
        height: "100%",
        display: "flex",
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: "#fff",
        paddingBottom: "50px",
    },
    itemC: {
        height: "100%",
        display: "flex",
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'start',
        backgroundColor: "#fff",
        paddingBottom: "50px",
    },
    politicaText: {
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'justify',
        fontSize: "12px",
    },
    politicaTitulo: {
        display: "flex",
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: "18px",
        color: "#2196f3",
        /* marginTop: "10px", */
        backgroundColor: "#e3f2fd",
        height: 10+"vh",
    },
    politicaTituloSecundario: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: "14px",
        color: "#2196f3",
        marginTop: "25px",
        backgroundColor: "#fff",
    },
});

class Cookies extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {deportes: []};
    }

    render() {

        const  classes  = this.props.classes;
        const { t } = this.props;
        return (
        <div className={classes.root}>
            <Grid container spacing={0} className={classes.politicaContenedor}>
                
                <Grid item sm={12} className={classes.politicaTitulo}>  
                <b>POLÍTICA DE COOKIES</b>
                </Grid>

                <Grid item sm={10} className={classes.politicaTituloSecundario}>  
                <b>{t("¿QUÉ SON LAS COOKIES?")}</b>
                </Grid>
                <Grid item sm={10} className={classes.politicaText}> 
                Una cookie es un fichero o pequeña pieza de información que se descarga en su equipo o dispositivo al acceder a determinadas páginas web.
                <br/>
                <br/>Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación del usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que se utilice su equipo, pueden utilizarse para reconocer al usuario.
                </Grid>
                
                <Grid item sm={10} className={classes.politicaTituloSecundario}>  
                <b>¿QUÉ COOKIES UTILIZA ESTA PÁGINA WEB?</b>
                </Grid>
                <Grid item sm={10} className={classes.politicaText}>
                Cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
                <br/>
                <br/>Esta página web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Usted puede rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, debe ser conocedor que la plena funcionabilidad de esta página web puede verse afectada. También puede rechazar el uso de las cookies y el uso de los datos relacionados con el sitio (incluyendo su dirección IP) a Google así como impedir el tratamiento de estos datos por Google mediante la descarga e instalación de un plug-in en el siguiente enlace: <Link target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=es">https://tools.google.com/dlpage/gaoptout?hl=es</Link>. Al utilizar esta página web, consideramos que consiente el tratamiento de información acerca de Usted por parte de Google en la forma y para los fines arriba indicados.
                <br/>
                <br/>Cookies funcionales: Las cookies funcionales son las que la plataforma establece para ayudar al sitio web a funcionar del modo esperado. Algunos archivos cookies son necesarios para permitirle navegar por nuestro sitio web y utilizar sus funciones. Sin estos archivos de cookies, no podemos proporcionarle adecuadamente los servicios que requiere, como procesar un pedido ó registrar un usuario. Utilizamos otros archivos de cookies para ayudar a reconocerle y recordarlo cuando inicia sesión en la plataforma, de modo que podamos recordar su configuración y preferencias, así como su idioma y región o su estado de conexión. Estos archivos de cookies también pueden ayudarnos a proporcionarle los servicios que ha solicitado, consultar sus pedidos o realizar un comentario u opinión sobre un producto. Estos archivos no realizan el seguimiento de su actividad de navegación en sitios web que no sean de la plataforma.
                <br/>
                <br/>Características de medios sociales. Nuestro sitio web puede utilizar las características de los medios sociales (“Características sociales”) proporcionadas por empresas y servicios como Facebook, Twitter y Google+ para permitirle compartir información a través de estos canales. Las funciones sociales están identificadas en nuestro sitio web con los logotipos de sus respectivas empresas. Estos archivos cookies son propiedad de terceras partes quienes las controlan, por lo que estas terceras partes son responsables de su uso. Como tal, no podemos garantizar que hayamos enumerado todas las cookies que puede recibir de las terceras partes, ya que estas podrían cambiarlas en cualquier momento en la medida en que cambien sus necesidades. Cuando visite una página integrada en la plataforma que incluya características sociales, su navegador establece una conexión directa con los servidores de la red social correspondiente. Las características sociales les proporcionan a las redes sociales la información a la que accedió en el website de la plataforma y, si inició sesión en la red social, su visita puede quedar registrada en su cuenta de ese servicio. Por ejemplo, si hace clic en el botón “Me gusta” de Facebook, esa información es transmitida desde su navegador directamente a Facebook y almacenada ahí. Incluso si no inició sesión en la red social, ni hace clic en una función social en el website de nuestra plataforma, existe la posibilidad de que una función social pueda transmitir su dirección IP a la red social.
                <br/>
                <br/>Para obtener más información sobre el objetivo y alcance del procesamiento de datos por parte de la red social, así como sus opciones para proteger su privacidad, consulte la política de privacidad en el sitio web de la red social correspondiente. Para su comodidad, a continuación compartimos los vínculos a las características sociales más utilizadas.
                </Grid>
                <Grid item sm={10} className={[classes.politicaText, classes.itemC]}> 
                <br/><Link target="_blank" className={classes.politicaText} href="https://www.facebook.com/about/privacy">
                Facebook: https://www.facebook.com/about/privacy
                </Link>
                <Link target="_blank" className={classes.politicaText} href="https://www.google.com/policies/privacy">
                Google+: https://www.google.com/policies/privacy
                </Link>
                <Link target="_blank" className={classes.politicaText} href="https://twitter.com/privacy">
                Twitter: https://twitter.com/privacy
                </Link>
                </Grid>
                <Grid item sm={10} className={classes.politicaTituloSecundario}>  
                <b>¿CÓMO PUEDE PERMITIR, BLOQUEAR O ELIMINAR LAS COOKIES?</b>
                </Grid>
                <Grid item sm={10} className={classes.politicaText}> 
                La mayoría de los navegadores están configurados de forma predeterminada para aceptar cookies. Usted puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su equipo o dispositivo. Si Usted bloquea las cookies puede afectar al funcionamiento y optimización de las páginas web que visite. Para más información sobre cada navegador, utilice las siguientes opciones:
                </Grid>
                <Grid item sm={10} className={[classes.politicaText, classes.itemC]}> 
                <br/><Link target="_blank" className={classes.politicaText} href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                Firefox: https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                </Link>
                <Link target="_blank" className={classes.politicaText} href="https://support.google.com/chrome/answer/95647?hl=es">
                Chrome: https://support.google.com/chrome/answer/95647?hl=es
                </Link>
                <Link  target="_blank" className={classes.politicaText} href="http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10">
                Internet Explorer: http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10
                </Link>
                <Link target="_blank" className={classes.politicaText} href="https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy">
                Edge: https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy
                </Link>
                <Link target="_blank" className={classes.politicaText} href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">
                Opera: http://help.opera.com/Windows/11.50/es-ES/cookies.html
                </Link>
                <Link  className={classes.politicaText} href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">
                iOS: http://support.apple.com/kb/HT1677?viewlocale=es_ES
                </Link>
                <Link target="_blank" className={classes.politicaText} href="https://support.google.com/accounts/answer/61416?hl=es">
                Android: https://support.google.com/accounts/answer/61416?hl=es
                </Link>
                </Grid>
            </Grid>
        </div>
        );
    }

};

export default withTranslation()(withStyles(useStyles)(Cookies));