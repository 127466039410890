import React from 'react';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const styles = theme => ({

  root: {
    flexGrow: 1,
    backgroundColor: "#e3f2fda1",
    backdropFilter: "blur(10px)",
    position: "fixed",
    bottom: "0px",
    left: "0",
    width: "100vw"
    
  },
  lang: {
    padding:0,
    minWidth: "40px"
  },
  logo:{
    padding: "5px 14px",
    height: "31px"
  },
  paper: {
    textAlign: 'right',
    padding: theme.spacing(1),
  },
  paperLink: {
    paddingRight: theme.spacing(2),
    fontSize: "12px",
    color: "#0279d8",
    "&:hover": {
      textDecoration: "none"
    }
  },
}
);

class PiePagina extends React.Component{
    
  constructor(props) {
        super(props);
        this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      //t('key'); // -> same as i18next.t
    });
  }

  render() {
      const  classes  = this.props.classes;
      const { t } = this.props;
      return (
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={6}>
              <img alt="Ayto Sagunto" src="/logo.png" className={classes.logo} />
            </Grid>
            <Grid item xs={6} className={classes.paper}>
                              
                <Link  href="/app_1.0.23.apk" className={classes.paperLink}>
                {t('Android app')}
                </Link>

                <Link  href="/politicaPrivacidad" className={classes.paperLink}>
                {t('Política de Privacidad')}
                </Link>

                <Link   href="/avisoLegal" className={classes.paperLink}>
                {t('Aviso Legal')}
                </Link>

                <Link href="/cookies" className={classes.paperLink}>
                {t('Cookies')}
                </Link>
            
                <Button className={classes.lang} onClick={() => this.changeLanguage('ca')}><img alt="Valencia" src="/val.png" /></Button>
                <Button className={classes.lang} onClick={() => this.changeLanguage('es')}><img alt="Castellano" src="/es.png" /></Button>
            </Grid>
          </Grid>
        </div>
    );
  };

}

export default withTranslation()(withStyles(styles)(PiePagina));

