import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { parseSpainFormatDate } from '../utils/Dates';
import { ReservaTitulo } from '../utils/Reservas';

const useStyles = theme => ({
  root: {
    display: 'flex',
    margin: "5px",
    fontSize: "24px"
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
    marginLeft: "auto"
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  title:{
    fontSize:"100%"
  },
  subtitle: {
    fontSize: "50%"
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: "80%"
    }
  },
  [theme.breakpoints.down('md')]: {
    title: {
      fontSize: "90%"
    }
  }
});

class BookingCard extends React.Component {

  constructor(props) {
    super(props);
    this.state= {open:false}
    this.remove = this.remove.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }
  
  remove(e){
    e.preventDefault();
    //this.props.onDelete(this.props.reserva.id);
    this.setState({open: true});
  }

  handleClose(){
    this.setState({open: false})
  }

  handleOk(){
    this.props.onDelete(this.props.reserva.id);
    this.setState({open: false});
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    
    return (
      <div>
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5" className={classes.title}>
              {t(ReservaTitulo(this.props.reserva))}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
            {this.props.reserva.Actividade.Instalacione.nombre}<br/>
            {t('Día')}: {parseSpainFormatDate(this.props.reserva.fecha)}<br/>
            {t('Hora')}: {this.props.reserva.Horario.hora_inicio.slice(0, -3)}-{this.props.reserva.Horario.hora_fin.slice(0, -3)}<br/>
            {t('Número de reservas')}: {this.props.reserva.num_reservas}
            </Typography>
          </CardContent>
          <div className={classes.controls}>
            {this.props.onDelete && 
            <IconButton aria-label="Eliminar" onClick={this.remove}>
              <Delete />
            </IconButton>
            }
          </div>
        </div>
        <CardMedia
          className={classes.cover}
          image={"/images/"+this.props.reserva.Actividade.imagen}
          title="Deporte"
        />
      </Card>



      <Dialog
      open={this.state.open}
      onClose={this.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{t("¿Estás seguro de eliminar esta reserva?")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {t("eliminar_confirmacion")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose} color="primary">
        {t('No eliminar')}
        </Button>
        <Button onClick={this.handleOk} color="secondary" autoFocus>
        {t('Eliminar mi reserva')}
        </Button>
      </DialogActions>
      </Dialog>

      </div>
    );
  }
};

export default withTranslation()(withRouter(withStyles(useStyles)(BookingCard)));