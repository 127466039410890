import { forwardRef } from 'react';
import React from 'react';
import API from "../../utils/API";
import {getHeaderAuth} from "../../utils/Auth";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { withTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: "blur(10px)",
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: "4px"
  },
  root:{
    padding: "150px 0 45px 0"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  background: {
      width:100+"vw",
      height:100+"vh",
      background: "url('/images/backgrounds/1.jpeg') no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "auto",
      padding: "40px 0"
  },
  cardsContainer: {
    margin: "auto",
    display: "flex"
  },
  
});

function mapStateToProps(state) {
  return {
    loggedIn: state.loggedIn,
    selUser: state.selUser
  };
}


class Cierres extends React.Component{

  constructor(props) {

      super(props);
      this.state = {items:[], actividades:[]};
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onDelete = this.onDelete.bind(this);
      this.newItem = this.newItem.bind(this);
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async componentDidMount() {
      
    let items = await API.get('/cierres/', getHeaderAuth());
    this.setState({items: items.data});


    let actividades = await API.get('/actividades/');
    let idata = {};
    for (let i of actividades.data){
        idata[i.id]= i.nombre + " - " + i.Instalacione.nombre;
    }
    this.setState({actividades: idata});
    
  }

  async onDelete(id){
    console.log("SE BORRA ELEMENTO CON ID: "+id);
    let result = await API.delete('/cierres/'+id, getHeaderAuth());
    if(result.data.status==="OK"){
      this.setState({items: this.state.items.filter(function(item) { 
        return item.id !== id 
      })});
    }else{
      alert(result.data.message);
    }
  }

  async newItem(newData){
    let result = await API.post('/cierres/', newData, getHeaderAuth());
    if(result.data.status==="ok"){
      newData["id"]= result.data.id;
      console.log(newData);
      this.setState({items: [...this.state.items, newData]});
      return;
    }else{
      throw new Error(result.data.message);
    }
    
  }

  render() {
    const  classes  = this.props.classes;
    const dias_semana_string = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    //const { t } = this.props;
    return (

      
        <div className={classes.root}>

          <Container>
            <MaterialTable
            icons={tableIcons}
            columns={[
              { title: "Actividad/Instalación", field: "ActividadeId", lookup: this.state.actividades},
              { title: "Día", 
                field: "dia", 
                render: rowData => { let d = new Date(rowData.dia); return(<p>{d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()}</p>) },
                editComponent: props => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      placeholder="Día"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      value={props.value}
                      onChange={(e)=>{props.onChange(e)}}
                    />
                  </MuiPickersUtilsProvider>
                )
              },
              { title: "Día Fin", 
                field: "dia_fin", 
                render: rowData => { 
                  if(rowData.dia_fin===undefined){
                    return "---";
                  }else{
                    let d = new Date(rowData.dia_fin); 
                    return(<p>{d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear()}</p>) 
                  }
                },
                editComponent: props => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      placeholder="Día"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      value={props.value}
                      onChange={(e)=>{props.onChange(e)}}
                    />
                  </MuiPickersUtilsProvider>
                )
              },
              { title: "Hora Inicio", field: "horaInicio"},
              { title: "Hora Fin", field: "horaCierre"},
              { title: "Días de la semana", field: "dias_semana",
                render: rowData => {
                  let r = ""
                  if(rowData.dias_semana!==null){
                    let dias = rowData.dias_semana.split(",")
                    for(let i of dias){
                      r = r+dias_semana_string[Number(i)]+" "
                    }
                  }
                  return r
                }, 
                editComponent: props => (
                  <select name="dias" multiple
                    onChange={(e)=>{
                      let selected=[];
                      for(let i of e.target.selectedOptions){
                        if(i.value!=="-1")
                          selected.push(i.value)
                      }
                      props.onChange(selected.join(",")); 
                      console.log("-----"+selected.join(","))
                    }}
                  >
                    <option value="-1">Ninguna</option>
                    <option value="1">Lunes</option>
                    <option value="2">Martes</option>
                    <option value="3">Miércoles</option>
                    <option value="4">Jueves</option>
                    <option value="5">Viernes</option>
                    <option value="6">Sábado</option>
                    <option value="0">Domingo</option>
                  </select>
                )
              },
              { title: "Motivo", field: "motivo"},
              { title: "Limite aforo", field: "limite_aforo", type: 'boolean'},
              { title: "Nuevo aforo", field: "nuevo_aforo", type: 'numeric'}
            ]}
            data={this.state.items}
            editable= {{
              onRowAdd: newData =>
              new Promise((resolve, reject) => {
                this.newItem(newData).then(()=>{
                  resolve()
                }).catch((e)=>{
                  alert(e);
                  reject()
                })
              }),
              onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                  //const dataDelete = [...this.state.users];
                  //const index = oldData.tableData.id;
                  this.onDelete(oldData.id).then(()=>{
                    //dataDelete.splice(index, 1);
                    //this.setState({reservas:dataDelete});
                    resolve();                  
                  })
              }),
            }}
            localization={
              { 
                body: { 
                  addTooltip: "Añadir",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  editRow: { 
                    deleteText: '¿Deseas borrar el Cierre?' ,
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Guardar"
                  } 
                }, 
                header:{
                  actions: "Acciones"
                },
                toolbar: {
                  searchTooltip: "Buscar", 
                  searchPlaceholder:"Buscar",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  exportName: "Exportar como"
                }, 
                pagination: {labelRowsSelect: "filas"} }}
            options={{
              exportButton: true,
              pageSizeOptions: [5,10,20,50,100,500, { value: -1, label: "All" }],
              filtering: true,
              headerStyle: {
                  padding: "12px",
                    fontSize: "0.8em",
                },
              cellStyle: {
                padding: "12px",
                fontSize: "0.8em",
              }
            }}
            title="Cierres de instalaciones"
          />
          </Container>
        </div>
  
    );
  };
  
}

export default withTranslation()(withRouter(withStyles(styles)(connect(mapStateToProps)(Cierres))))